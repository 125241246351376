import '../styles/Content.css';

const Content = () => {
  return (
    <div className='Content'>
      <div className='JP'>
私は泣いたことがない<br />
灯の消えた街角で<br />
速い車にのっけられても<br />
急にスピンかけられても恐くなかった<br />
赤いスカーフがゆれるのを<br />
不思議な気持ちで見てたけど<br />
私泣いたりするのは違うと感じてた<br /><br />

私は泣いたことがない<br />
つめたい夜の真ん中で<br />
いろんな人とすれ違ったり<br />
投げKiss受けとめたり投げ返したり<br />
そして友達が変わるたび<br />
想い出ばかりがふえたけど<br />
私泣いたりするのは違うと感じてた<br /><br />

飾りじゃないのよ涙は　HA HAN<br />
好きだと言ってるじゃないの　HO HO<br />
真珠じゃないのよ涙は　HA HAN<br />
きれいなだけならいいけど<br />
ちょっと悲しすぎるのよ涙は　HO HO HO…<br />
轉載來自 ※ Mojim.com　魔鏡歌詞網<br /><br />

私は泣いたことがない<br />
本当の恋をしていない<br />
誰の前でもひとりきりでも<br />
瞳の奥の涙は隠していたから<br />
いつか恋人に会える時<br />
私の世界が変わる時<br />
私泣いたりするんじゃないかと感じてる<br />
きっと泣いたりするんじゃないかと感じてる<br /><br />

飾りじゃないのよ涙は　HA HAN<br />
好きだと言ってるじゃないの　HO HO<br />
真珠じゃないのよ涙は　HA HAN<br />
きれいなだけならいいけど<br />
ちょっと悲しすぎるのよ涙は<br /><br />

飾りじゃないのよ涙は　HA HAN<br />
かがやくだけならいいけど　HO HO<br />
ダイヤと違うの涙は　HA HAN<br />
さみしいだけならいいけど<br />
ちょっと悲しすぎるのよ涙は<br />
ラララ……<br />
      </div>
    </div>
  );
}

export default Content;