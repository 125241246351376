import '../styles/Navbar.css';

const Navbar = () => {
  return (
    <div className='Navbar'>
      <div className='wordart'>
        <img src="logo192.png" alt="Logo" />
        <span className='name'>Kashi</span>
      </div>
      <div className='search'>
        <input type="text" />
      </div>
      <div className='other'>
        <span>More</span>
      </div>
    </div>
  );
}

export default Navbar;