import '../styles/Footer.css';

const Footer = () => {
  return (
    <div className='Footer'>
      <div className='version'>
        <span>Kashi Lyrics Platofrm</span>
        <span>Version a0.0.1</span>
      </div>
      <div className='copyright'>
        <span>&copy; {new Date().getFullYear()} Michał Czyż</span>
        <span>All rights reserved.</span>
      </div>
      <div className='contact'>
        <span><a href="/">Report an issue</a></span>
        <span><a href="mailto:mike@czyz.icu">Contact</a></span>
      </div>
    </div>
  );
}

export default Footer;