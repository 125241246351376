import '../styles/App.css';
import Navbar from './Navbar';
import Footer from './Footer';
import Content from './Content';

const App = () => {
  return (
    <div className="App">
      <Navbar />
      <Content />
      <Footer />
    </div>
  );
}

export default App;
